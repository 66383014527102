import React from 'react';
import { Auth } from 'aws-amplify';
import {
  Button,
} from '@aws-amplify/ui-react';
import { Navigate } from 'react-router-dom';

function About() {

  return (
    <div className="row">
      <div className="col-md-4">
        <h1>About</h1>
      </div>
    </div>
  );
};

export default About;