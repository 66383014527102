import React from 'react';
import { Auth } from 'aws-amplify';
import {
    Button,
} from '@aws-amplify/ui-react';
import { Navigate } from 'react-router-dom';

function Home() {

    return (
        <div className="row justify-content-center align-items-start">
            <div className="col-4 p-0 d-flex justify-content-center">
                <h1 className="ms-0 ps-0">Home</h1>
            </div>
            <div className="col-4 p-0 d-flex justify-content-center">
                <h1 className="ms-0 ps-0">Home</h1>
            </div>
            <div className="col-4 p-0 d-flex justify-content-center">
                <h1 className="ms-0 ps-0">Home</h1>
            </div>
        </div>
    );
};

export default Home;