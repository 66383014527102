import logo from './logo.svg';
import './App.css';
import { ThemeProvider, Grid } from "@aws-amplify/ui-react";
import { Outlet } from "react-router-dom";
import Navigation from './Components/Navigation/navigation';

function App() {
  return (
    <>
      <ThemeProvider>
          <div>
            <Navigation />
          </div>
          <div className="container-fluid fullscreen">
            <Outlet />
          </div>
      </ThemeProvider>
    </>
  );
}

export default App;
