
import { React, useEffect, useState, useCallback, useContext } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  Container
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import logo from '../../Static/Images/candle.svg'


function Navigation() {
  
  return (
    <div className="container-fluid" style={{ marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0 }}>
      <Navbar bg="dark" expand="md" variant="dark" className="container-fluid" style={{ marginLeft: 0, marginRight: 0, maxWidth: "100%"}}>
        <Container className="container-fluid" style={{ maxWidth: "100%" }}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <LinkContainer to="/home">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/resume">
                <Nav.Link>Resume</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>

        </Container>
      </Navbar>
    </div>
  );
};

export default Navigation;